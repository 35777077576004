import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import LogoCompleta from "components/logoCompleta";
import { useState } from "react";
import { Link } from "react-scroll";
import { useAppSelector } from "store";
import { useConfig } from "store/config";

// const pages = ['sobre', 'portfolio', 'contato'];
const pages = [
    {
        name: 'home',
        link: 'home'
    },
    {
        name: 'sobre',
        link: 'sobre'
    },
    {
        name: 'portfolio',
        link: 'portfolio'
    },
    {
        name: 'contato',
        link: 'contato'
    },
];

const TopBar = () => {
    const { linkWhatsApp } = useAppSelector(useConfig)
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };


    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth={"lg"}>
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor: 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow: '0 0 1px rgba(245, 120, 9, 0.7), 1px 1.5px 2px -1px rgba(245, 120, 9, 0.65), 4px 4px 12px -2.5px rgba(245, 120, 9, 0.65)',
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <LogoCompleta />
                            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                {pages.map((page, i) => (
                                    <Link
                                        key={i}
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        to={page.link}
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <Button
                                            sx={{ color: 'white', display: 'block', textTransform: 'lowercase', mr: 1 }}
                                        >
                                            {page.name}
                                        </Button>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                component="a"
                                href={linkWhatsApp ?? "#"}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Orçamento Grátis
                            </Button>
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >   
                                     {pages.map((page, i) => (
                                        <Link
                                            key={i}
                                            spy={true}
                                            smooth={true}
                                            duration={500}
                                            to={page.link}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button
                                                sx={{ color: 'white', display: 'block', textTransform: 'lowercase', mb: 1 }}
                                            >
                                                {page.name}
                                            </Button>
                                        </Link>
                                    ))}
                                    <Divider sx={{mb:2}}/>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        component="a"
                                        href={linkWhatsApp ?? "#"}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Orçamento Grátis
                                    </Button>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}

export default TopBar;