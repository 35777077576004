import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BannerSection from "pages/home/components/banner";
import SobreSection from "pages/home/components/sobre";
import QualidadesSection from "pages/home/components/qualidades";
import ProcessoSection from "pages/home/components/processo";
import ServicosSection from "pages/home/components/servicos";
import ProgramasSection from "pages/home/components/programas";
import ProjetosSection from "pages/home/components/projetos";
import DepoimentosSection from "pages/home/components/depoimentos";
import ClientesSection from "pages/home/components/clientes";
import ContatoSection from "pages/home/components/contato";


const HomePage = () => {
    return (
        <>
            <BannerSection />
            <SobreSection />
            <QualidadesSection />
            <ProcessoSection />
            <ServicosSection />
            <ProgramasSection />
            <ProjetosSection />
            <DepoimentosSection />
            <ClientesSection />
            <ContatoSection />
        </>
    )
}

export default HomePage