import {createTheme} from "@mui/material";
import {ptBR} from '@mui/material/locale';

export const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat'
        ].join(','),
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#F57809',
            light: '#FAB103',
            dark: '#FA2E03',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#B01AA7',
            light: '#C71461',
            dark: '#6613BD',
            contrastText: '#000000'
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& label': {
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        fontWeight: 'bold',
                    },
                    '& .MuiInputBase-root': {
                        backgroundColor: '#ffffff',
                        color: '#000000',
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    background: `linear-gradient(to right, #F57809 0%, #B01AA7 100%)`,
                },
                root: {
                   borderRadius: '20px',
                   '&:hover': {
                       background: `linear-gradient(to right, #F57809 0%, #B01AA7 100%)`,
                       borderRadius: '20px'
                   }
                },
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    scrollBehavior: 'smooth !important',
                    "&::-webkit-scrollbar": {
                        width: '8px',
                    },
                    "::-webkit-scrollbar-thumb ": {
                        background: `linear-gradient(to right, #F57809 0%, #B01AA7 100%)`,
                        borderRadius: '20px'
                    },
                    "::-webkit-scrollbar-track": {
                        background: 'transparent',
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        background: `linear-gradient(to right, #F57809 0%, #B01AA7 100%)`,
                    },
                    "::-webkit-scrollbar-track:hover": {
                        background: 'rgba(255,255,255,0.8)',
                    },
                },
                body: {
                    background: `#000000`,
                },
                a: {
                    textDecoration: 'none',
                },
            }
        }
    }
}, ptBR);

export default theme;