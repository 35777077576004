import Slider from "react-slick";
import ClienteItem from "components/carrousselClientes/cliente";
import { useCallback, useEffect, useState } from "react";
import { Cliente } from "types/cliente";
import ClienteService from "services/cliente";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";


const CarrousselCliente = () => {
    const [clientes , setClientes] = useState<Cliente[]>([])
    const [loading, setLoading] = useState(false)
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));


    const getClientes = useCallback(() => {
        setLoading(true)
        ClienteService.getAll().then((response) => {
            setClientes(response)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        getClientes()
    }, [getClientes])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: downSm ? 1 : clientes.length < 6 ? clientes.length : 6,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        autoplay: true,
    };

    if (loading)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Carregando...</div></Typography>
        </Box>
    )

    if (!clientes.length)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Nenhum cliente encontrado</div></Typography>
        </Box>
    )


    return (
        <Slider {...settings} >
            {clientes.map((cliente, i) => (
                <ClienteItem cliente={cliente} key={i} />
            ))}
        </Slider>
    )
}

export default CarrousselCliente