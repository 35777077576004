import { Box, Button } from "@mui/material";
import TopBar from "components/topBar";
import { Outlet } from "react-router-dom";
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "store";
import { configActions, useConfig } from "store/config";
import ConfiguracoesService from "services/configuracoes";
import { Link } from "react-scroll";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';


const Layout = () => {
    const { nome, foto, linkBehance, linkInstagram, linkWhatsApp, profissao, textoSobre } = useAppSelector(useConfig)
    const dispatch = useAppDispatch();
    const [hasToTop, setHasToTop] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setHasToTop(true)
            } else {
                setHasToTop(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [hasToTop])

    const getConfig = useCallback(() => {
        if (nome && foto && linkBehance && linkInstagram && linkWhatsApp && profissao && textoSobre) return;
        dispatch(configActions.setLoading(true));
        ConfiguracoesService.getWithImage()
            .then((response) => {
                let configState = {
                    foto: response.foto,
                    linkBehance: response.linkBehance,
                    linkInstagram: response.linkInstagram,
                    linkWhatsApp: response.linkWhatsApp,
                    nome: response.nome,
                    profissao: response.profissao,
                    textoSobre: response.textoSobre,
                }
                dispatch(configActions.setConfig(configState))
            })
            .finally(() => { dispatch(configActions.setLoading(false)) })
    }, [dispatch, foto, linkBehance, linkInstagram, linkWhatsApp, nome, profissao, textoSobre]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);


    return (
        <>
            <TopBar />
            <Box component={"main"} sx={{ display: "flex", flex: 1, justifyContent: "center", flexDirection: 'column', alignItems: "center" }}>
                <Outlet />
            </Box>
            {
                hasToTop &&
                <Box sx={{width: 50, height: 50, position: "fixed", bottom: 15, right: 50}}>
                    <Link
                        spy={true}
                        smooth={true}
                        duration={500}
                        to={"home"}
                        style={{ textDecoration: 'none' }}
                    >
                        <Button variant="contained"><KeyboardDoubleArrowUpIcon /></Button>
                    </Link>
                </Box>
            }
        </>
    );
}

export default Layout;