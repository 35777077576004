import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/index";


export type AuthState = {
    email?: string;
    uid?: string;
    authenticated: boolean;
}


const initialState: AuthState = {
    email: undefined,
    uid: undefined,
    authenticated: false
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<{email: string, uid: string}>) => {
            state.email = action.payload.email;
            state.uid = action.payload.uid;
            state.authenticated = true;
        },
        logout: (state) => {
            state.email = undefined;
            state.uid = undefined;
            state.authenticated = false;
        }
    }
})


export default authSlice.reducer;

export const authActions = authSlice.actions;

export const useAuth = (state: RootState) => state.auth;
