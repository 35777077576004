import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import SlickCarroussel from "components/slickCarroussel";
import Container from "@mui/material/Container";


const ProjetosSection = () => {
    return (
        <>
            <Container component={"section"} maxWidth={false}  sx={{minHeight: "600px", padding: '0!important', display: 'flex', justifyContent: "center", alignItems: "start"}} id="portfolio">
                <Grid container>
                    <Grid item xs={12} sx={{textAlign: "center", mb: 5}}>
                        <Typography sx={{fontSize: 32, fontWeight: 400}}>Projetos</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        // sx={{backgroundColor: "yellow"}}
                    >
                        <SlickCarroussel />
                    </Grid>
                </Grid>
            </Container>
            <Container component={"section"} sx={{minHeight: "350px", display: 'flex', justifyContent: "center", alignItems: "start"}}>
                <Grid container maxWidth={"md"}>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Grid item xs={12} md={3.6} sx={{textAlign: 'center'}}>
                            <Typography
                                fontSize={80}
                                fontWeight={600}
                                sx={{
                                    background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                                    backgroundClip: 'text',
                                    color: 'transparent',
                                }}
                            >+4</Typography>
                            <Typography fontSize={24} fontWeight={600}>anos de<br/> êxperiencia</Typography>
                        </Grid>
                        <Grid item xs={12} md={.5} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Box sx={{mt: {xs: 2, md: 0},backgroundColor: 'white', width: {xs: 200, md: 3}, height: {xs:3,md:200}}} />
                        </Grid>
                        <Grid item xs={12} md={3.6} sx={{textAlign: 'center'}}>
                            <Typography
                                fontSize={80}
                                fontWeight={600}
                                sx={{
                                    background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                                    backgroundClip: 'text',
                                    color: 'transparent',
                                }}
                            >+50</Typography>
                            <Typography fontSize={24} fontWeight={600}>empresas e<br/> eventos atendidos</Typography>
                        </Grid>
                        <Grid item xs={12} md={.5} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Box sx={{mt: {xs: 2, md: 0},backgroundColor: 'white', width: {xs: 200, md: 3}, height: {xs:3,md:200}}} />
                        </Grid>
                        <Grid item xs={12} md={3.6} sx={{textAlign: 'center'}}>
                            <Typography
                                fontSize={80}
                                fontWeight={600}
                                sx={{
                                    background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                                    backgroundClip: 'text',
                                    color: 'transparent',
                                }}
                            >+75</Typography>
                            <Typography fontSize={24} fontWeight={600}>projetos<br/> entregues</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ProjetosSection