import {ReactNode} from "react";
import {Card, CardContent} from "@mui/material";

type Props = {
    children: ReactNode
}

const MyCard = ({children}: Props) => {
    return(
        <Card sx={{ width: "100%" }}>
            <CardContent sx={{p:3}}>
                {children}
            </CardContent>
        </Card>
    )
}

export default MyCard