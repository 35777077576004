import {Grid} from "@mui/material";
import {Navigate, Outlet} from "react-router-dom";
import {useAppSelector} from "store";
import {useAuth} from "store/auth";


const AuthLayout = () => {
    const selector = useAppSelector(useAuth)

    if(selector.authenticated){
        return <Navigate to={"/painel"} />
    }

    return (
        <Grid container component="main" sx={{ height: '100vh', display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <Outlet />
        </Grid>
    )
}

export default AuthLayout