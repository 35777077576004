import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

type Props = {
    imagem: any;
    alt: string;
    texto: string;
}

const CustomListItem = ({imagem, alt, texto}: Props) => {
    return(
        <ListItem>
            <ListItemButton>
                <ListItemIcon>
                    <img src={imagem} alt={alt}/>
                </ListItemIcon>
                <ListItemText primary={texto} sx={{ml: 1}}/>
            </ListItemButton>
        </ListItem>
    )
}

export default CustomListItem;