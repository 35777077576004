import {Grid, List} from "@mui/material";
import CustomListItem from "components/listItem";
import Cavalo from "assets/icones/cavalo.png";
import Lampada from "assets/icones/lampada.png";
import Selo from "assets/icones/selo.png";
import Engrenagem from "assets/icones/engrenagem.png";
import Celular from "assets/images/celular.png";
import Container from "@mui/material/Container";


const QualidadesSection = () => {
    return(
        <Container component={"section"} sx={{minHeight: "600px", display: 'flex', justifyContent: "center"}}>
            <Grid container maxWidth={"md"} sx={{display: 'flex', alignItems: 'center'}}>
                <Grid item md={6} sx={{display: "flex", justifyContent: "start"}}>
                    <List>
                        <CustomListItem imagem={Cavalo} alt={"cavalo-icone"} texto={"estratégia"}/>
                        <CustomListItem imagem={Lampada} alt={"lampada-icone"} texto={"inovação e criatividade"}/>
                        <CustomListItem imagem={Selo} alt={"selo-icone"} texto={"alta qualidade"}/>
                        <CustomListItem imagem={Engrenagem} alt={"engrenagem-icone"}
                                        texto={"serviços personalizados"}/>
                    </List>
                </Grid>
                <Grid item md={6} sx={{display: "flex", justifyContent: "end"}}>
                    <img src={Celular} width={"100%"} alt={"celular"}/>
                </Grid>
            </Grid>
        </Container>
    )
}


export default QualidadesSection;