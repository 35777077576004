import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { Contato } from "types/contato";
import { database } from "utils/firebase";

const dbcollection = collection(database, 'contato');


const ContatoService = {
    getAll: async (filtro = "TODOS") => {
        try{
            let q;
            let qs;
            if(filtro === "LIDOS"){
                q = query(dbcollection, where("lido", "==", true));
                qs = await getDocs(q);
            }else if(filtro === "NAO_LIDOS"){
                q = query(dbcollection, where("lido", "==", false));
            }else{
                q = query(dbcollection,  orderBy("lido", "asc"));
            }
            qs = await getDocs(q);
            let depoimentos: Contato[] = [];
            qs.docs.forEach((doc) => {
                let data = doc.data();
                depoimentos.push({ ...data, id: doc.id} as Contato);
            });
            return depoimentos;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    create: async (contato: Contato) => {
        try{
            const docRef = doc(dbcollection);
            await setDoc(docRef, contato);
            return {...contato, id: docRef.id} as Contato;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    updateStatus: async (id: string) => {
        try{
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                data.lido = !data?.lido;
                await setDoc(docRef, data);
            }
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    delete: async (id: string) => {
        try{
            const docRef = doc(dbcollection, id);
            await deleteDoc(docRef);            
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },

}


export default ContatoService;