import {Grid, TextField, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import {useForm} from "react-hook-form";
import ContatoService from "services/contato";
import { useAppDispatch } from "store";
import { configActions } from "store/config";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Contato } from "types/contato";


type FormState = {
    nome: string,
    email: string,
    assunto: string,
    mensagem: string
}


const FormContato = () => {
    const {register, handleSubmit, reset} = useForm<FormState>()
    const dispatch = useAppDispatch()
    const theme = useTheme();

    const onSubmit = (data: FormState) => {
        dispatch(configActions.setLoading(true))
        let temp : Contato = {
            ...data,
            lido: false,
            data: moment().format("DD/MM/YYYY HH:mm:ss")
        }
        ContatoService.create(temp)
            .then(() => {
                let options: SweetAlertOptions = {
                    title: 'Mensagem foi enviada com sucesso!',
                    html: `Em breve entrarei em contato com você!`,
                    icon: 'success',
                    iconColor: theme.palette.success.light,
                    color: "#fff",
                    background: theme.palette.background.paper,
                    confirmButtonColor: theme.palette.primary.main,
                    confirmButtonText: 'OK!'
                }
                Swal.fire(options)
                    .finally(() => {
                        reset();
                    })
            })
            .catch((error) => { 
                let options: SweetAlertOptions = {
                    title: 'Ocorreu um erro ao enviar a mensagem!',
                    html: `Tente novamente mais tarde!`,
                    icon: 'error',
                    iconColor: theme.palette.error.light,
                    color: "#fff",
                    background: theme.palette.background.paper,
                    confirmButtonColor: theme.palette.primary.main,
                    confirmButtonText: 'OK!'
                }
                Swal.fire(options)
                    .finally(() => {
                        reset();
                    })
                    console.error(error)
            })
            .finally(() => { dispatch(configActions.setLoading(false))})
    }



    return(
        <Grid component={"form"} container spacing={2} onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12}>
                <TextField
                    {...register("nome")}
                    label={"Nome"}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...register("email")}
                    label={"Email"}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...register("assunto")}
                    label={"Assunto"}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...register("mensagem")}
                    label={"Mensagem"}
                    fullWidth
                    required
                    multiline
                    rows={4}
                />
            </Grid>
            <Grid item xs={12}>
               <Button type={"submit"} fullWidth variant={"contained"}>Enviar</Button>
            </Grid>
        </Grid>
    )
}

export default FormContato