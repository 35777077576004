import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { deleteObject, getBytes, ref, uploadBytes } from 'firebase/storage';
import { Projeto, ProjetoUpdate } from 'types/projeto';
import { database, storage } from 'utils/firebase';

const dbcollection = collection(database, 'projetos');

function _arrayBufferToBase64( buffer:any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


async function _getImagem (imagem: string){
    const imageRef = ref(storage, imagem);
    const imageBytes = await getBytes(imageRef);
    const base64String = _arrayBufferToBase64(imageBytes);
    return `data:image/png;base64,${base64String}`;
}

const ProjetoService = {
    getAll: async () => {
        try{
            const qs = await getDocs(dbcollection);
            let projetos: Projeto[] = [];
            qs.docs.forEach((doc) => {
                let data = doc.data();
                projetos.push({ ...data, id: doc.id} as Projeto);
            });
            for(let i = 0; i < projetos.length; i++){
                if(!projetos[i].imagem) continue;
                projetos[i].imagem = await _getImagem(projetos[i].imagem);
            }
            return projetos;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    getById: async (id: string) => {
        try{
            let projeto: Projeto;
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            let imagem = "";
            if(data?.imagem){
                imagem = await _getImagem(data.imagem);
            }
            projeto = {...data, id: docSnap.id, imagem} as Projeto;
            return projeto;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    create: async (data: ProjetoUpdate) => {
        try{
            let projetoAtual: Projeto = {...data, imagem: ''};
            const docRef = doc(dbcollection);
            let img = data.imagem.length > 0 ? data.imagem[0] : null;
            if(img){
                let imgName = `projetos/${docRef.id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                projetoAtual.imagem = imgName;
            }
            await setDoc(docRef, projetoAtual);
            return {...projetoAtual, id: docRef.id} as Projeto;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    update: async  (id: string, data: ProjetoUpdate) => {
        try{
            let projetoAtual: Projeto;
            const docRef = doc(dbcollection, id);
            let img = data.imagem.length > 0 ? data.imagem[0] : null;
            if(img){
                projetoAtual = {...data, imagem: ''};
                let imgName = `projetos/${id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                projetoAtual.imagem = imgName;
            }else{
                if(data.imagem){
                    // @ts-ignore
                    delete data.imagem;
                }
                // @ts-ignore
                projetoAtual = {...data} as Projeto;
            }
            await setDoc(docRef, projetoAtual, {merge: true});
            return {...projetoAtual, id} as Projeto;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    delete: async (id: string) => {
        try{
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if(data?.imagem){
                const imageRef = ref(storage, data.imagem);
                await deleteObject(imageRef);
            }
            await deleteDoc(docRef);            
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
}



export default ProjetoService;