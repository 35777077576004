import {useForm} from "react-hook-form";
import {Box, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import AuthService from "services/auth";
import {useAppDispatch} from "store";
import {authActions} from "store/auth";
import {toast} from "react-toastify";
import {configActions} from "store/config";

type FormState = {
    email: string
    password: string
}


const LoginPage = () => {
    const {register, handleSubmit, formState: {errors}} = useForm<FormState>()
    const dispatch = useAppDispatch();

    const onSubmit = async (data: FormState) => {
        dispatch(configActions.setLoading(true))
        AuthService.login(data.email, data.password)
            .then((response: { uid: any; email: any; }) => {
                if(response.uid && response.email){
                    dispatch(authActions.login({ email: response.email , uid: response.uid}))
                }
            })
            .catch(() => {
                toast.error("Email ou senha inválidos")
            })
            .finally(() => {
                dispatch(configActions.setLoading(false))
            })
    }

    return (
        <Box>
            <Typography component="h1" variant="h5" textAlign={"center"}>
                Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                <TextField
                    {...register("email", {required: {value: true, message: "Email é obrigatório"}})}
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    error={!!errors.email}
                    helperText={errors.email?.message}
                />
                <TextField
                    {...register("password", {required: {value: true, message: "Senha é obrigatória"}})}
                    margin="normal"
                    required
                    fullWidth
                    label="Senha"
                    type="password"
                    autoComplete="current-password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Entrar
                </Button>
            </Box>
        </Box>
    )
 }

export default LoginPage