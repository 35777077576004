import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import LogoCompleta from "components/logoCompleta";
import LogoIcone from "components/logoIcone";
import ButtonItem from "components/painelLayout/topBar/ButtonItem";
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import AuthService from 'services/auth';
import { useAppDispatch } from "store";
import { authActions } from "store/auth";
import { configActions } from 'store/config';


const menus = [
    {label: "Painel", link: "/painel"},
    {label: "Configurações", link: "/painel/configuracoes"},
    {label: "Projetos", link: "/painel/projetos"},
    {label: "Depoimentos", link: "/painel/depoimentos"},
    {label: "Clientes", link: "/painel/clientes"},
    {label: "Contato", link: "/painel/contato"},
]

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    const handleChangePage = (link: string) => {
        navigate(link);
    }

    const handleLogout = () => {
        dispatch(configActions.setLoading(true));
        AuthService.logout()
            .then(() => {
                dispatch(authActions.logout())
            })
            .catch((err) => {
                toast.error(JSON.stringify(err));
            })
            .finally(() => {
                handleCloseUserMenu();
                dispatch(configActions.setLoading(false));
            })
    }



    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{display: {xs: "none", md: "flex"}}}>
                        <LogoCompleta fontSize={24} />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >   
                            {
                                menus.map((item, index) => {
                                    return (
                                        <MenuItem key={index} onClick={() => {
                                            handleCloseNavMenu();
                                            handleChangePage(item.link)
                                        }} >
                                            <Typography textAlign="center">{item.label}</Typography>
                                        </MenuItem>
                                    )
                                })
                            }                            
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <LogoIcone />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        { menus.map((item, index) => <ButtonItem key={index} link={item.link} label={item.label} handleClose={() => {handleCloseNavMenu()}}/>)}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={() => {
                                handleLogout();
                            }}>
                                <Typography textAlign="center">Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
