import {auth} from "utils/firebase";
import {signInWithEmailAndPassword, signOut} from "firebase/auth";


const AuthService = {
    login: async (email: string, password: string) => {
        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            return res.user;
        }catch (e:any) {
            await Promise.reject(e);
            throw new Error(e.message);
        }
    },
    logout: async () => {
        try {
            await signOut(auth);
        }catch (e:any) {
            await Promise.reject(e);
            throw new Error(e.message);
        }
    }
}

export default AuthService;