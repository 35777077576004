import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import ServicoItem from "components/servicoItem";
import IconDesign from "assets/icones/Icon_design.png";
import IconVideo from "assets/icones/Icon_video.png";
import IconSocial from "assets/icones/Icon_Social.png";
import IconCamera from "assets/icones/Icon_camera.png";
import Container from "@mui/material/Container";


const ServicosSection = () => {
    return(
        <Container component={"section"} sx={{minHeight: "400px", display: 'flex', justifyContent: "center", alignItems: "start", mt:8}}>
            <Grid container maxWidth={"md"}>
                <Grid item xs={12} sx={{textAlign: "center", mb: 5}}>
                    <Typography sx={{fontSize: 32, fontWeight: 400}}>Serviços oferecidos</Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    spacing={3}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Grid item xs={12} md={3}>
                        <ServicoItem img={IconDesign} title={<>Design<br /> Gráfico e UI</>}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ServicoItem img={IconVideo} title={<>Edição de<br /> video</>}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ServicoItem img={IconSocial} title={<>Social<br /> Media</>} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ServicoItem img={IconCamera} title={<>Manipulação<br /> de fotos</>}/>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


export default ServicosSection;