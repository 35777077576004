import CircularProgress from '@mui/material/CircularProgress';
import {Backdrop} from '@mui/material';
import {useAppSelector} from "store";
import {useConfig} from "store/config";

const ContentLoader = () => {
    const {loading} = useAppSelector(useConfig)

    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 10000, backgroundColor: (t) => t.palette.grey[900] }}
            open={loading}
        >
            <CircularProgress sx={{color:(theme) => theme.palette.primary.main}} size={100}/>
        </Backdrop>
    );
}


export default ContentLoader;