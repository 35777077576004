import { Box, Theme, useMediaQuery } from "@mui/material";
import { Projeto } from "types/projeto";

type Props = {
    projeto: Projeto;
}

const CarrousselItem = ({projeto}: Props) => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    return (
        <a href={projeto.link ?? ""} target="_blank" rel="noreferrer">
            <Box
                sx={{
                    backgroundImage: `url(${projeto.imagem})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: downSm ? 250 : 400,
                    height: 350,
                    borderRadius: 5,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // color: "transparent",
                    '&:hover:after': {
                        content: `"${projeto.nome}"`,
                        fontSize: 24,
                        fontWeight: 600,
                        color: "white",
                        textShadow: "0px 0px 10px rgba(0,0,0,1)",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: `linear-gradient(to left, rgba(176, 26, 167, 0.5) 0%, rgba(245, 120, 9, 0.5) 100%)`,
                        transition: 'all 0.3s ease',
                        borderRadius: 5,
                        opacity: 1,
                    },    
                }}
            >
            </Box>
        </a>
    )
}


export default CarrousselItem