import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { deleteObject, getBytes, ref, uploadBytes } from 'firebase/storage';
import { Depoimento, DepoimentoUpdate } from 'types/depoimento';
import { database, storage } from 'utils/firebase';

const dbcollection = collection(database, 'depoimentos');

function _arrayBufferToBase64( buffer:any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


async function _getImagem (imagem: string){
    const imageRef = ref(storage, imagem);
    const imageBytes = await getBytes(imageRef);
    const base64String = _arrayBufferToBase64(imageBytes);
    return `data:image/png;base64,${base64String}`;
}

const DepoimentoService = {
    getAll: async () => {
        try{
            const qs = await getDocs(dbcollection);
            let depoimentos: Depoimento[] = [];
            qs.docs.forEach((doc) => {
                let data = doc.data();
                depoimentos.push({ ...data, id: doc.id} as Depoimento);
            });
            for(let i = 0; i < depoimentos.length; i++){
                if(!depoimentos[i].foto) continue;
                depoimentos[i].foto = await _getImagem(depoimentos[i].foto);
            }
            return depoimentos;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    getById: async (id: string) => {
        try{
            let depoimento: Depoimento;
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            let foto = "";
            if(data?.foto){
                foto = await _getImagem(data.foto);
            }
            depoimento = {...data, id: docSnap.id, foto} as Depoimento;
            return depoimento;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    create: async (data: DepoimentoUpdate) => {
        try{
            let depoimentoAtual: Depoimento = {...data, foto: ''};
            const docRef = doc(dbcollection);
            let img = data.foto.length > 0 ? data.foto[0] : null;
            if(img){
                let imgName = `depoimentos/${docRef.id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                depoimentoAtual.foto = imgName;
            }
            await setDoc(docRef, depoimentoAtual);
            return {...depoimentoAtual, id: docRef.id} as Depoimento;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    update: async  (id: string, data: DepoimentoUpdate) => {
        try{
            let depoimentoAtual: Depoimento;
            const docRef = doc(dbcollection, id);
            let img = data.foto.length > 0 ? data.foto[0] : null;
            if(img){
                depoimentoAtual = {...data, foto: ''};
                let imgName = `depoimentos/${id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                depoimentoAtual.foto = imgName;
            }else{
                if(data.foto){
                    // @ts-ignore
                    delete data.foto;
                }
                // @ts-ignore
                depoimentoAtual = {...data} as Depoimento;
            }
            await setDoc(docRef, depoimentoAtual, {merge: true});
            return {...depoimentoAtual, id} as Depoimento;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    delete: async (id: string) => {
        try{
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if(data?.foto){
                const imageRef = ref(storage, data.foto);
                await deleteObject(imageRef);
            }
            await deleteDoc(docRef);            
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
}



export default DepoimentoService;