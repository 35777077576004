import {Box, styled} from "@mui/material";
import Typography from "@mui/material/Typography";

type Props = {
    img: string;
    title1: string;
    title2: string;
}

const Imagem = styled(Box)(({theme}) => ({
    width: 100,
    height: 100,
    background: `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
}));

const FerramentasItem = ({img, title1, title2}: Props) => {

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Box
                sx={{
                    maskImage: `url(${img})`,
                    maskRepeat: 'no-repeat',
                    maskSize: "cover",
                    maskPosition: "center"
                }}
            >
                <Imagem  />
            </Box>
            <Typography textAlign={"center"} fontWeight={600} sx={{mt:2}}>
                {title1} <br /> {title2}
            </Typography>
        </Box>
    )
}


export default FerramentasItem;