import {Box, styled} from "@mui/material";
import LogoIcone from "components/logoIcone";
import Instagram from "assets/icones/Icon_Instagram.png";
import Behance from "assets/icones/Icon_Behance.png";
import { useAppSelector } from "store";
import { useConfig } from "store/config";

const Img = styled('img')({
    width: 100,
    height: 100,
    margin: 10
});

const BannerSection = () => {
    const {linkInstagram, linkBehance} = useAppSelector(useConfig)

    return (
        <Box component={"section"} sx={{minHeight: "100vh", display: 'flex', alignItems: "center", pt: "60px"}} id="home">
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                <LogoIcone width={"auto"} height={300} fontSize={300}/>
                <Box sx={{display: 'flex'}}>
                    <a href={linkInstagram ?? ""} target="_blank" rel="noreferrer">
                        <Img src={Instagram} alt="Instagram"/>
                    </a>
                    <a href={linkBehance ?? ""} target="_blank" rel="noreferrer">
                        <Img src={Behance} alt="Behance"/>
                    </a>
                </Box>
            </Box>
        </Box>
    )
}

export default BannerSection