import {Box, Card} from "@mui/material";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Typography from "@mui/material/Typography";
import { Depoimento } from "types/depoimento";

type Props = {
    depoimento: Depoimento;
}


const DepoimentoItem = ({depoimento}: Props) => {
    return (
        <Card
            variant="elevation"
            sx={{
                backgroundColor: "white",
                width: "98%",
                height: 450,
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
            }}
        >
            <FormatQuoteIcon
                sx={{
                    fontSize: 100,
                    color: "black",
                    transform: "rotate(180deg)",
                    // background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                    // backgroundClip: "text",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 125,
                    width: 125,
                    padding: .5,
                    background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                    borderRadius: "50%",
                }}
            >
                <img
                    src={depoimento.foto ?? ''}
                    alt="Foto de perfil"
                    style={{height: "100%", width: "100%", borderRadius: "50%", objectFit: "cover", backgroundColor: "#dddddd"}}
                />
            </Box>
            <Typography color={"black"} textAlign={"center"} fontSize={12} sx={{mt:2, p:2}} fontWeight={600}>
                “{depoimento.depoimento}”
            </Typography>
            <Typography color={"black"} textAlign={"center"} fontSize={20} sx={{mt:2, p:0}} fontWeight={800}>
                {depoimento.nome}
            </Typography>
            <Typography color={"black"} textAlign={"center"} fontSize={14} sx={{ p:0}} fontWeight={600}>
                {depoimento.agencia}
            </Typography>
        </Card>
    )
}

export default DepoimentoItem