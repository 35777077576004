import {Box, Typography, styled} from "@mui/material";
import { Cliente } from "types/cliente";

type Props = {
    cliente: Cliente;
}

const Img = styled('img')(({ theme }) => ({}));

const ClienteItem = ({cliente}: Props) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 250,
            width: 250,
            padding: 1,
        }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 200,
                    width: 200,
                    padding: .5,
                    background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                    borderRadius: "50%",
                }}
            >
                <Img
                    src={cliente.imagem ?? ''}
                    alt="Foto de perfil"
                    sx={{
                        height: "100%",
                        width: "100%", 
                        borderRadius: "50%", 
                        objectFit: "cover",
                        backgroundColor: "#dddddd",
                    }}
                />
            </Box>
            <Typography color={"white"} textAlign={"center"} fontSize={20} sx={{p:0}} fontWeight={800}>
                {cliente.nome}
            </Typography>
        </Box>
    )
}

export default ClienteItem