import { Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import MyCard from "components/card";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ContatoService from "services/contato";
import { useAppDispatch } from "store";
import { configActions } from "store/config";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Contato } from "types/contato";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const ListContatoPage = () => {
    const [contatos, setContatos] = useState<Contato[]>([]);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [filtro, setFiltro] = useState<string>("TODOS");

    const getAllContatos = useCallback(() => {
        dispatch(configActions.setLoading(true));
        ContatoService.getAll(filtro)
            .then((response) => {
                setContatos(response);
            })
            .catch((error) => {
                toast.error(JSON.stringify(error));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            });

    }, [dispatch, filtro]);

    useEffect(() => {
        getAllContatos();
    }, [getAllContatos]);

    const handleDelete = (id?: string) => {
        if (id) {
            let options: SweetAlertOptions = {
                title: 'Tem certeza que deseja deletar este contato?',
                html: `<p>Esta ação não poderá ser desfeita!</p>`,
                icon: 'warning',
                iconColor: theme.palette.error.light,
                color: "#fff",
                background: theme.palette.background.paper,
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: 'Sim, deletar!',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
            }

            Swal.fire(options)
                .then((result) => {
                    if (result.isConfirmed) {
                        dispatch(configActions.setLoading(true));
                        ContatoService.delete(id)
                            .then(() => {
                                toast.success("Contato deletado com sucesso!");
                            })
                            .catch((error) => {
                                toast.error(JSON.stringify(error));
                            })
                            .finally(() => {
                                dispatch(configActions.setLoading(false));
                                getAllContatos();
                            });
                    }
                })

        }
    }

    const handleChangeStatus = (id?: string) => {
        if (id) {
            dispatch(configActions.setLoading(true));
            ContatoService.updateStatus(id)
                .then(() => {
                    toast.success("Status alterado com sucesso!");
                })
                .catch((error: any) => {
                    toast.error(JSON.stringify(error));
                })
                .finally(() => {
                    dispatch(configActions.setLoading(false));
                    getAllContatos();
                });
        }
    }



    return (
        <MyCard>
            <Grid container>
                <Grid container item md={12}>
                    <Grid item md={6}>
                        <Typography variant="h6">Contatos</Typography>
                    </Grid>
                    <Grid item md={6} sx={{ display: "flex", justifyContent: "end" }}>
                        <FormControl fullWidth>
                            <InputLabel>Age</InputLabel>
                            <Select
                                value={filtro}
                                label="Age"
                                fullWidth
                                onChange={(event) => setFiltro(event.target.value as string)}
                            >
                                <MenuItem value={"TODOS"}>Todos</MenuItem>
                                <MenuItem value={"LIDOS"}>Lidos</MenuItem>
                                <MenuItem value={"NAO_LIDOS"}>Não Lidos</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    contatos.map((contato) => (
                        <Grid container item xs={12} spacing={2} key={contato.id} sx={{ backgroundColor: (theme) => theme.palette.background.paper, display: "flex", alignItems: "center", p: 3, mt: 3 }}>
                            <Grid item xs={12} md={3} >
                                <Typography>Nome:</Typography>
                                {contato.nome}
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Typography>Email:</Typography>
                                {contato.email}
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <Typography>Recebido:</Typography>
                                {contato.data}
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <Typography>Status:</Typography>
                                {contato.lido ? <Chip label="Lido" color="success" size="small" /> : <Chip label="Não lido" color="error" size="small" />}
                            </Grid>
                            <Grid item xs={12} md={2} sx={{ display: "flex", gap: 2 }}>
                                <Button onClick={() => { handleChangeStatus(contato.id)}} variant="contained">{contato.lido ? <CancelIcon /> : <CheckCircleOutlineIcon/> }</Button>
                                <Button onClick={() => { handleDelete(contato.id) }} variant="contained"><DeleteIcon /></Button>
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Typography>Mensagem:</Typography>
                                {contato.mensagem}
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </MyCard>
    )
}


export default ListContatoPage
