import {Box, styled} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ReactNode} from "react";

type Props = {
    img: string,
    title: ReactNode,
}

const Imagem = styled('img')({
    width: 100,
    height: 100,
    margin: 10
});

const ServicoItem = ({img, title} : Props) => {
   return(
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: 200,
                borderRadius: 5,
                background: (theme) => `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
            }}
        >
            <Imagem src={img} />
            <Typography
                fontSize={20}
                fontWeight={600}
                textAlign={"center"}
                color={"white"}
            >
                {title}
            </Typography>

        </Box>
   )
}

export default ServicoItem