import {Box, Grid, styled} from "@mui/material";
import Container from "@mui/material/Container";
import LogoIcone from "components/logoIcone";
import Typography from "@mui/material/Typography";
import Instagram from "assets/icones/Icon_Instagram.png";
import Behance from "assets/icones/Icon_Behance.png";
import FormContato from "components/formContato";
import { useAppSelector } from "store";
import { useConfig } from "store/config";

const Imagem = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    background: `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
}));

const ContatoSection = () => {
    const {textoContato, linkInstagram, linkBehance} = useAppSelector(useConfig);


    return(
        <Container component={"section"} maxWidth={"lg"}  sx={{minHeight: "450px", padding: '0!important', display: 'flex', justifyContent: "center", alignItems: "start"}} id="contato">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    sx={{padding: 3}}
                >
                    <LogoIcone fontSize={80}/>
                    <Typography sx={{maxWidth: 300}}>
                        {textoContato}
                    </Typography>
                    <Typography fontSize={24} fontWeight={800} sx={{mt:3}}>
                        Siga-me nas redes sociais
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "row", mt:1}}>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <a href={linkInstagram ?? ''} target="_blank" rel="noreferrer">
                                <Box
                                    sx={{
                                        maskImage: `url(${Instagram})`,
                                        maskRepeat: 'no-repeat',
                                        maskSize: "cover",
                                        maskPosition: "center",
                                        width: 50,
                                        height: 50,
                                    }}
                                >
                                    <Imagem  />
                                </Box>
                            </a>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", ml: 1}}>
                            <a href={linkBehance ?? ''} target="_blank" rel="noreferrer">
                                <Box
                                    sx={{
                                        maskImage: `url(${Behance})`,
                                        maskRepeat: 'no-repeat',
                                        maskSize: "cover",
                                        maskPosition: "center",
                                        width: 50,
                                        height: 50,
                                    }}
                                >
                                    <Imagem  />
                                </Box>
                            </a>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{padding: 3}}
                >
                    <FormContato />
                </Grid>
            </Grid>
        </Container>
    )
}


export default ContatoSection