import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type LogoCompletaProps = {
    width?: number;
    height?: number;
    fontSize?: number;
};


const LogoCompleta = ({width, height, fontSize}: LogoCompletaProps) => {
    return (
      <Box width={width} height={height} sx={{display: "flex", p:1}}>
          <Typography
              letterSpacing={0}
              fontSize={fontSize}
              fontWeight={100}
          >
              Eduarda
          </Typography>
          <Typography
              letterSpacing={0}
              fontSize={fontSize}
              fontWeight={900}
              sx={{
                  background: (theme) => `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
          >
              Weitzel
          </Typography>
      </Box>
    );
};

export default LogoCompleta;


// background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);