import {Avatar, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Container from "@mui/material/Container";
import { useAppSelector } from "store";
import { useConfig } from "store/config";


const SobreSection = () => {
    const {linkWhatsApp, foto, textoSobre, nome, profissao} = useAppSelector(useConfig)

    return (
        <Container component={"section"} sx={{minHeight: "500px", display: 'flex', justifyContent: "center"}} id="sobre">
            <Grid container maxWidth={"md"} sx={{display: 'flex', alignItems: 'center', justifyContent: {xs: "center", md: "left"}, width: "100%"}}>
                <Grid item md={4} sx={{display: "flex", justifyContent: "center"}}>
                    <Avatar sx={{height: 250, width: 250}} src={foto ?? ""}>{foto ? foto : `${nome?.split(" ")[0][0]}${nome?.split(" ")[1][0] ?? ''}`}</Avatar>
                </Grid>
                <Grid item md={8}>
                    <Typography variant={'h4'} fontWeight={800} sx={{textAlign: {xs: 'center', md: 'left'}}} >{nome},</Typography>
                    <Typography variant={'h6'}  sx={{textAlign: {xs: 'center', md: 'left'}}}>{profissao}</Typography>
                    <Typography fontWeight={400} sx={{mt: 1, textAlign: {xs: 'center', md: 'left'}}}>{textoSobre}</Typography>
                    <a href={linkWhatsApp ?? ""} target="_blank" rel="noreferrer">
                        <Button color={"primary"} variant={"contained"} sx={{mt: 1, width: {xs: "100%", md: "auto"}}}>Entre em contato <WhatsAppIcon
                            sx={{ml: 2}}/></Button>
                    </a>
                </Grid>
            </Grid>
        </Container>
  )
}


export default SobreSection