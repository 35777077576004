import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import FerramentasItem from "components/ferramentasItem";
import IconAfterEffects from "assets/icones/Icon_Ae.png";
import IconIllustrator from "assets/icones/Icon_Ai.png";
import IconLightroom from "assets/icones/Icon_Lr.png";
import IconPhotoshop from "assets/icones/Icon_ps.png";
import IconPremiere from "assets/icones/Icon_Pr.png";
import IconMidjourney from "assets/icones/Icon_Midjourney.png";
import Container from "@mui/material/Container";


const ProgramasSection = () => {
    return(
        <Container component={"section"} sx={{minHeight: "375px", display: 'flex', justifyContent: "center", alignItems: "start", mt:10}}>
            <Grid container maxWidth={"md"}>
                <Grid item xs={12} sx={{textAlign: "center", mb: 5}}>
                    <Typography sx={{fontSize: 32, fontWeight: 400}}>Ferramentas que eu utilizo</Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconAfterEffects} title1={"adobe"} title2={"after effects"} />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconIllustrator} title1={"adobe"} title2={"illustrator"}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconLightroom} title1={"adobe"} title2={"lightroom"}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconPhotoshop} title1={"adobe"} title2={"photoshop"}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconPremiere} title1={"adobe"} title2={"premiere"}/>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FerramentasItem img={IconMidjourney} title1={"midjourney"} title2={" "}/>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ProgramasSection