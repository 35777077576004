import { CssBaseline, ThemeProvider } from "@mui/material";
import "assets/css/app.css";
import { theme } from "assets/theme";
import AuthLayout from "components/authLayout";
import Layout from "components/layout";
import ContentLoader from "components/loader";
import PainelLayout from "components/painelLayout";
import LoginPage from "pages/auth/login";
import HomePage from "pages/home";
import EditClientePage from "pages/painel/clientes/edit";
import ListClientesPage from "pages/painel/clientes/list";
import ConfiguracoesPage from "pages/painel/configuracoes";
import ListContatoPage from "pages/painel/contato/list";
import Dashboard from "pages/painel/dashboard";
import EditDepoimentosPage from "pages/painel/depoimentos/edit";
import ListDepoimentosPage from "pages/painel/depoimentos/list";
import EditProjetoPage from "pages/painel/projetos/edit";
import ListProjetosPage from "pages/painel/projetos/list";
import { Provider } from "react-redux";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import 'sweetalert2/dist/sweetalert2.css';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: '/',
                element: <HomePage/>,
            }
        ]
    },
    {
        path: 'login',
        element: <Layout/>,
        children: [
            {
                index: true,
                element: <Navigate to={"/auth/login"}/>,
            }
        ]
    },
    {
        path: 'auth',
        element: <AuthLayout/>,
        children: [
            {
                index: true,
                element: <Navigate to={"/auth/login"}/>,
            },
            {
                path: 'login',
                element: <LoginPage/>,
            }
        ]
    },
    {
        path: 'painel',
        element: <PainelLayout/>,
        children: [
            {
                index: true,
                element: <Dashboard/> ,
            },
            {
                path: 'configuracoes',
                element: <ConfiguracoesPage/> ,
            },
            {
                path: 'projetos',
                children: [
                    {
                        index: true,
                        element: <ListProjetosPage />,
                    },
                    {
                        path: ':id',
                        element: <EditProjetoPage />,
                    },
                ]
            },
            {
                path: 'depoimentos',
                children: [
                    {
                        index: true,
                        element: <ListDepoimentosPage />,
                    },
                    {
                        path: ':id',
                        element: <EditDepoimentosPage />,
                    },
                ]
            },
            {
                path: 'clientes',
                children: [
                    {
                        index: true,
                        element: <ListClientesPage />,
                    },
                    {
                        path: ':id',
                        element: <EditClientePage />,
                    },
                ]
            },
            {
                path: 'contato',
                children: [
                    {
                        index: true,
                        element: <ListContatoPage />,
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <Layout/>,
        children: [
            {
                path: '*',
                element: <HomePage/>,
            }
        ]
    }
]);

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <ContentLoader />
                    <ToastContainer
                        position="top-right"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={'colored'}
                    />
                    <RouterProvider router={router}/>
                </ThemeProvider>
            </PersistGate >
        </Provider>
    );
}

export default App;