


const DashboardPage = () => {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    )
}


export default DashboardPage