import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "store/index";


export type ConfigState = {
    loading: boolean;
    foto: string | null;
    linkBehance: string | null;
    linkInstagram: string | null;
    linkWhatsApp: string | null;
    nome: string | null;
    profissao: string | null;
    textoSobre: string | null;
    textoContato: string | null;
}


const initialState: ConfigState = {
    loading: false,
    foto: null,
    linkBehance: null,
    linkInstagram: null,
    linkWhatsApp: null,
    nome: null,
    profissao: null,
    textoSobre: null,
    textoContato: null,
}


export const configSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setConfig: (state, action: PayloadAction<any>) => {
            state.foto = action.payload.foto;
            state.linkBehance = action.payload.linkBehance;
            state.linkInstagram = action.payload.linkInstagram;
            state.linkWhatsApp = action.payload.linkWhatsApp;
            state.nome = action.payload.nome;
            state.profissao = action.payload.profissao;
            state.textoSobre = action.payload.textoSobre;
            state.textoContato = action.payload.textoContato;
        },
    }
})


export default configSlice.reducer;

export const configActions = configSlice.actions;

export const useConfig = (state: RootState) => state.config;
