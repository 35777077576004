import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MyCard from "components/card";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfiguracoesService from "services/configuracoes";
import { useAppDispatch } from "store";
import { configActions } from "store/config";

export type FormState = {
    foto: FileList;
    linkBehance: string;
    linkInstagram: string;
    linkWhatsApp: string;
    nome: string;
    profissao: string;
    textoSobre: string;
    textoContato: string;
}

const ConfiguracoesPage = () => {
    const {register, handleSubmit, reset } = useForm<FormState>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getValues = useCallback(() => {
        dispatch(configActions.setLoading(true));
            ConfiguracoesService.get()
                .then((response) => {
                    reset({...response, foto: undefined})
                })
                .catch((error) => { toast.error(JSON.stringify(error)) })
                .finally(() => { dispatch(configActions.setLoading(false)) })
    }, [dispatch, reset])

    useEffect(() => {
        getValues()
    }, [getValues]);


    const onSubmit = (data: FormState) => {
        let config = {
            ...data,
        }
        dispatch(configActions.setLoading(true));
        ConfiguracoesService.update(config)
            .then(() => {
                toast.success("Configurações atualizadas com sucesso!");
                navigate("/painel/configuracoes")
            })
            .catch((error) => { toast.error(JSON.stringify(error)) })
            .finally(() => { dispatch(configActions.setLoading(false)) })
    }

    return (
        <MyCard>
            <Grid container>
                <Grid item md={12}>
                    <Typography variant="h6">Configurações</Typography>
                </Grid>
                <Grid container item md={12} spacing={2} component={"form"} onSubmit={handleSubmit(onSubmit)}>
                    <Grid item md={6}>
                        <TextField
                            {...register("linkBehance")}
                            label="Link Behance"
                            fullWidth
                            margin="normal"
                            type={"url"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            {...register("linkInstagram")}
                            label="Link Instagram"
                            fullWidth
                            margin="normal"
                            type={"url"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            {...register("linkWhatsApp")}
                            label="Link Whatsapp"
                            fullWidth
                            margin="normal"
                            type={"url"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            {...register("nome")}
                            label="Nome"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            {...register("profissao")}
                            label="Profissão"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            {...register("foto")}
                            label="Foto"
                            fullWidth
                            margin="normal"
                            type={"file"}
                            inputProps={{
                                accept: 'image/jpeg, image/png, image/jpg, image/webp, image/gif'
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            {...register("textoSobre")}
                            label="Sobre"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            {...register("textoContato")}
                            label="Contato"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Button type={"submit"} variant={"contained"} fullWidth>Salvar</Button>
                    </Grid>
                </Grid>
            </Grid>
        </MyCard>
    )
}

export default ConfiguracoesPage