import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import MyCard from "components/card";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import ClienteService from 'services/cliente';
import { useAppDispatch } from "store";
import { configActions } from "store/config";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Cliente } from 'types/cliente';

const ListClientesPage = () => {
    const dispatch = useAppDispatch();
    const [clientes, setClientes] = useState<Cliente[]>([]);
    const navigate = useNavigate();
    const theme = useTheme();

    const getAllClientes = useCallback(() => {
        dispatch(configActions.setLoading(true));
        ClienteService.getAll()
            .then((response) => {
                setClientes(response);
            })
            .catch((error) => {
                toast.error(JSON.stringify(error));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            });
    }, [dispatch]);


    useEffect(() => {
        getAllClientes();
    }, [getAllClientes]);

    const handleEdit = (id?: string) => {
        if(id){
            navigate(`/painel/clientes/${id}`);
        }
    }

    const handleDelete = (id?: string) => {
        if(id){
            let options:SweetAlertOptions = {
                title: 'Tem certeza que deseja deletar este cliente?',
                html: `<p>Esta ação não poderá ser desfeita!</p>`,
                icon: 'warning',
                iconColor: theme.palette.error.light,
                color: "#fff",
                background: theme.palette.background.paper,
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: 'Sim, deletar!',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
            }

            Swal.fire(options)
                .then((result) => {
                    if (result.isConfirmed) {
                        dispatch(configActions.setLoading(true));
                        ClienteService.delete(id)
                        .then(() => {
                            toast.success("Projeto deletado com sucesso!");
                        })
                        .catch((error) => {
                            toast.error(JSON.stringify(error));
                        })
                        .finally(() => {
                            dispatch(configActions.setLoading(false));
                            getAllClientes();
                        });
                    }
                })
           
        }
    }


    return (
        <MyCard>
            <Grid container>
                <Grid container item md={12}>
                    <Grid item md={6}>
                        <Typography variant="h6">Clientes</Typography>
                    </Grid>
                    <Grid item md={6} sx={{display: "flex", justifyContent: "end"}}>
                        <Button variant='contained' onClick={() => navigate(`/painel/clientes/add`)}>Adicionar</Button>
                    </Grid>
                </Grid>
                {
                    clientes.map((cliente) => (
                        <Grid container item xs={12} key={cliente.id} sx={{backgroundColor: (theme) => theme.palette.background.paper, display: "flex", alignItems: "center", p:3, mt:3}}>
                            <Grid item xs={12} md={4.5} >
                                <img src={cliente.imagem} alt={cliente.nome} width={100} />
                            </Grid>
                            <Grid item xs={12} md={4.5} >
                                <Typography>Nome:</Typography>
                                {cliente.nome}
                            </Grid>
                            <Grid item xs={12} md={3} sx={{display: "flex", gap: 2}}>
                                <Button onClick={() => { handleEdit(cliente.id)}} variant="contained"><EditIcon /></Button>
                                <Button onClick={() => { handleDelete(cliente.id)}} variant="contained"><DeleteIcon /></Button>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </MyCard>
    )
}

export default ListClientesPage;