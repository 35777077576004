import { Button, Grid, Typography, useTheme } from "@mui/material"
import MyCard from "components/card"
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DepoimentoService from "services/depoimento";
import { useAppDispatch } from "store";
import { configActions } from "store/config";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { Depoimento } from "types/depoimento";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const ListDepoimentosPage = () => {
    const [depoimentos, setDepoimentos] = useState<Depoimento[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const getALlDepoimentos = useCallback(() => {
        dispatch(configActions.setLoading(true));
        DepoimentoService.getAll()
            .then((response) => {
                setDepoimentos(response);
            })
            .catch((error) => {
                toast.error(JSON.stringify(error));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            });

    }, [dispatch]);

    useEffect(() => {
        getALlDepoimentos();
    }, [getALlDepoimentos]);

    const handleDelete = (id?: string) => {
        if(id){
            let options:SweetAlertOptions = {
                title: 'Tem certeza que deseja deletar este depoimento?',
                html: `<p>Esta ação não poderá ser desfeita!</p>`,
                icon: 'warning',
                iconColor: theme.palette.error.light,
                color: "#fff",
                background: theme.palette.background.paper,
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: 'Sim, deletar!',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
            }

            Swal.fire(options)
                .then((result) => {
                    if (result.isConfirmed) {
                        dispatch(configActions.setLoading(true));
                        DepoimentoService.delete(id)
                        .then(() => {
                            toast.success("Depoimento deletado com sucesso!");
                        })
                        .catch((error) => {
                            toast.error(JSON.stringify(error));
                        })
                        .finally(() => {
                            dispatch(configActions.setLoading(false));
                            getALlDepoimentos();
                        });
                    }
                })
           
        }
    }

    const handleEdit = (id?: string) => {
        if(id){
            navigate(`/painel/depoimentos/${id}`);
        }
    }



    return (
        <MyCard>
            <Grid container>
                <Grid container item md={12}>
                    <Grid item md={6}>
                        <Typography variant="h6">Depoimentos</Typography>
                    </Grid>
                    <Grid item md={6} sx={{display: "flex", justifyContent: "end"}}>
                        <Button variant='contained' onClick={() => navigate(`/painel/depoimentos/add`)}>Adicionar</Button>
                    </Grid>
                </Grid>
               {
                    depoimentos.map((depoimento) => (
                        <Grid container item xs={12} key={depoimento.id} sx={{backgroundColor: (theme) => theme.palette.background.paper, display: "flex", alignItems: "center", p:3, mt:3}}>
                            <Grid item xs={12} md={3} >
                                <img src={depoimento.foto} alt={depoimento.nome} width={100} />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Typography>Nome:</Typography>
                                {depoimento.nome}
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <Typography>Agência</Typography>
                                {depoimento.agencia}
                            </Grid>
                            <Grid item xs={12} md={3} sx={{display: "flex", gap: 2}}>
                                <Button onClick={() => { handleEdit(depoimento.id)}} variant="contained"><EditIcon /></Button>
                                <Button onClick={() => { handleDelete(depoimento.id)}} variant="contained"><DeleteIcon /></Button>
                            </Grid>
                            <Grid item xs={12} md={12} >
                            <Typography>Depoimento:</Typography>
                                {depoimento.depoimento}
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </MyCard>
    )
}

export default ListDepoimentosPage