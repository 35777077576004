import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { deleteObject, getBytes, ref, uploadBytes } from 'firebase/storage';
import { Cliente, ClienteUpdate } from 'types/cliente';
import { database, storage } from 'utils/firebase';

const dbcollection = collection(database, 'clientes');

function _arrayBufferToBase64( buffer:any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


async function _getImagem (imagem: string){
    const imageRef = ref(storage, imagem);
    const imageBytes = await getBytes(imageRef);
    const base64String = _arrayBufferToBase64(imageBytes);
    return `data:image/png;base64,${base64String}`;
}

const ClienteService = {
    getAll: async () => {
        try{
            const qs = await getDocs(dbcollection);
            let clientes: Cliente[] = [];
            qs.docs.forEach((doc) => {
                let data = doc.data();
                clientes.push({ ...data, id: doc.id} as Cliente);
            });
            for(let i = 0; i < clientes.length; i++){
                if(!clientes[i].imagem) continue;
                clientes[i].imagem = await _getImagem(clientes[i].imagem);
            }
            return clientes;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    getById: async (id: string) => {
        try{
            let cliente: Cliente;
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            let imagem = "";
            if(data?.imagem){
                imagem = await _getImagem(data.imagem);
            }
            cliente = {...data, id: docSnap.id, imagem} as Cliente;
            return cliente;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    create: async (data: ClienteUpdate) => {
        try{
            let clienteAtual: Cliente = {...data, imagem: ''};
            const docRef = doc(dbcollection);
            let img = data.imagem.length > 0 ? data.imagem[0] : null;
            if(img){
                let imgName = `clientes/${docRef.id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                clienteAtual.imagem = imgName;
            }
            await setDoc(docRef, clienteAtual);
            return {...clienteAtual, id: docRef.id} as Cliente;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    update: async  (id: string, data: ClienteUpdate) => {
        try{
            let clienteAtual: Cliente;
            const docRef = doc(dbcollection, id);
            let img = data.imagem.length > 0 ? data.imagem[0] : null;
            if(img){
                clienteAtual = {...data, imagem: ''};
                let imgName = `clientes/${id}.jpg`;
                const mountainsRef = ref(storage, imgName);
                await uploadBytes(mountainsRef, img);
                clienteAtual.imagem = imgName;
            }else{
                if(data.imagem){
                    // @ts-ignore
                    delete data.imagem;
                }
                // @ts-ignore
                clienteAtual = {...data} as Cliente;
            }
            await setDoc(docRef, clienteAtual, {merge: true});
            return {...clienteAtual, id} as Cliente;
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
    delete: async (id: string) => {
        try{
            const docRef = doc(dbcollection, id);
            const docSnap = await getDoc(docRef);
            const data = docSnap.data();
            if(data?.imagem){
                const imageRef = ref(storage, data.imagem);
                await deleteObject(imageRef);
            }
            await deleteDoc(docRef);            
        }catch(e:any){
            await Promise.reject(e);
            throw new Error(e);
        }
    },
}



export default ClienteService;