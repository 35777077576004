import Button from "@mui/material/Button";
import * as React from "react";
import {useMatch, useNavigate, useResolvedPath} from "react-router-dom";

type Props = {
    link: string;
    handleClose: () => void;
    label: string;
}

const ButtonItem = ({link, handleClose, label}: Props) => {
    const resolved = useResolvedPath(link);
    const match = useMatch({path: resolved.pathname, end: true});
    const navigate = useNavigate();

    return(
        <Button
            onClick={() => {
                handleClose();
                navigate(link);
            }}
            sx={{ my: 2, color: 'white', display: 'block', ml:2 }}
            variant={match ? "contained" : "text"}
        >
            {label}
        </Button>
    )
}


export default ButtonItem