import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CarrousselDepoimento from "components/carrousselDepoimento";


const DepoimentosSection = () => {
    return(
        <Container component={"section"} maxWidth={false}  sx={{minHeight: "700px", padding: '0!important', display: 'flex', justifyContent: "center", alignItems: "start"}}>
            <Grid container>
                <Grid item xs={12} sx={{textAlign: "center", mb: 5, mt: 3, px: {xs: 3, md: 0}}}>
                    <Typography sx={{fontSize: 32, fontWeight: 400}}>O que os clientes dizem?</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    // sx={{backgroundColor: "yellow"}}
                >
                    <CarrousselDepoimento />
                </Grid>
            </Grid>
        </Container>
    )
}

export default DepoimentosSection