import Container from "@mui/material/Container";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CarrousselCliente from "components/carrousselClientes";


const ClientesSection = () => {
    return(
        <Container component={"section"} maxWidth={false}  sx={{minHeight: "450px", padding: '0!important', display: 'flex', justifyContent: "center", alignItems: "start"}}>
            <Grid container>
                <Grid item xs={12} sx={{textAlign: "center", mb: 5}}>
                    <Typography sx={{fontSize: 32, fontWeight: 400}}>Empresas e eventos atendidos</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    // sx={{backgroundColor: "yellow"}}
                >
                    <CarrousselCliente />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ClientesSection