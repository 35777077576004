import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import MyCard from "components/card";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProjetoService from "services/projeto";
import { useAppDispatch } from "store";
import { configActions } from "store/config";
import { PLACEHOLDER } from "utils/constants";

type FormState = {
    nome: string;
    imagem: FileList;
    link: string;
}

const EditProjetoPage = () => {
    const { id } = useParams<{ id: string }>();
    const isEdit =  id !== "add";
    const { register, handleSubmit, reset } = useForm<FormState>();
    const dispatch = useAppDispatch();
    const [image, setImage] = useState("");
    const navigate = useNavigate();


    const handleImageChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            dispatch(configActions.setLoading(true));
            let img = URL.createObjectURL(e.target.files[0]);
            setImage(img);
            dispatch(configActions.setLoading(false));
        }
    }

    const getProjeto = useCallback(() => {
        if (!id || !isEdit) return;
        dispatch(configActions.setLoading(true));
        ProjetoService.getById(id)
            .then((res) => {
                reset({
                    nome: res.nome,
                    link: res.link,
                });
                setImage(res.imagem);
            })
            .catch((err: any) => {
                toast.error(JSON.stringify(err));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            })
    }, [dispatch, id, isEdit, reset]);

    useEffect(() => {
        getProjeto()
    }, [getProjeto]);


    const onSubmit = (data: FormState) => {
        if (id && isEdit) {
            handleUpdate(data);
        } else {
            handleCreate(data);
        }
    }

    const handleCreate = (data: FormState) => {
        dispatch(configActions.setLoading(true));
        ProjetoService.create(data)
            .then(() => {
                toast.success("Projeto criado com sucesso!");
                navigate("/painel/projetos");
            })
            .catch((err: any) => {
                toast.error(JSON.stringify(err));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            })

    }

    const handleUpdate = (data: FormState) => {
        if (!id) return;
        dispatch(configActions.setLoading(true));
        ProjetoService.update(id, data)
            .then(() => {
                toast.success("Projeto atualizado com sucesso!");
                navigate("/painel/projetos");
            })
            .catch((err: any) => {
                toast.error(JSON.stringify(err));
            })
            .finally(() => {
                dispatch(configActions.setLoading(false));
            })
    }


    return (
        <MyCard>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Typography variant="h6">{id ? "Editar" : "Adicionar"} projeto</Typography>
                </Grid>
                <Grid
                    item
                    md={6}
                    spacing={2}
                    component={"form"}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        mt: 3,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                    }}
                >
                    <TextField
                        label="Nome"
                        {...register("nome")}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ mt: 3 }}
                    />
                    <TextField
                        label="Link"
                        {...register("link")}
                        type="url"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ mt: 3 }}
                    />
                    <Box sx={{ display: "flex", mt: 3 }}>
                        <TextField
                            label="Imagem"
                            type="file"
                            {...register("imagem")}
                            onChange={handleImageChange}
                            fullWidth
                            inputProps={{
                                accept: 'image/jpeg, image/png, image/jpg, image/webp, image/gif'
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Button variant="contained" fullWidth color="primary" type="submit" sx={{ mt: 3 }}>Salvar</Button>
                </Grid>
                <Grid
                    item
                    md={6}
                    spacing={2}
                    component={"form"}
                    sx={{
                        mt: 3,
                    }}
                >
                    <Box
                        sx={{
                            border: "1px solid #444",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <img src={image ? image : PLACEHOLDER} alt="preview" width={"50%"} />
                    </Box>
                </Grid>
            </Grid>
        </MyCard>
    )
}


export default EditProjetoPage;