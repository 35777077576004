import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type LogoCompletaProps = {
    width?: number | string;
    height?: number | string;
    fontSize?: number;
};


const LogoIcone = ({width, height, fontSize}: LogoCompletaProps) => {
    return (
      <Box width={width} height={height} sx={{display: "flex", p:1, alignItems: "center"}}>
          <Typography
              letterSpacing={0}
              fontSize={fontSize}
              fontWeight={900}
              sx={{
                  p:0,
                  m:0,
                  background: (theme) => `linear-gradient(to right, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
          >
              W
          </Typography>
      </Box>
    );
};

export default LogoIcone;


// background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);