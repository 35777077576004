import {useAppSelector} from "store";
import {useAuth} from "store/auth";
import {Navigate, Outlet} from "react-router-dom";
import {Box} from "@mui/material";
import ResponsiveAppBar from "components/painelLayout/topBar";


const PainelLayout = () => {
    const {authenticated} = useAppSelector(useAuth);

    if(!authenticated) {
        return <Navigate to={"/auth/login"}/>
    }


    return (
        <Box sx={{height: "100vh", display: "flex", flexDirection: "column"}}>
            <ResponsiveAppBar/>
            <Box sx={{p:3}}>
                <Outlet />
            </Box>
        </Box>
    )
}

export default PainelLayout