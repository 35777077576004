import Slider from "react-slick";
import CarrousselItem from "components/slickCarroussel/carrousselItem";
import { useCallback, useEffect, useState } from "react";
import { Projeto } from "types/projeto";
import ProjetoService from "services/projeto";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";


const SlickCarroussel = () => {
    const [projetos , setProjetos] = useState<Projeto[]>([])
    const [loading, setLoading] = useState(false)
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const getProjetos = useCallback(() => {
        setLoading(true)
        ProjetoService.getAll().then((response) => {
            setProjetos(response)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        getProjetos()
    }, [getProjetos])


    const settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: downSm ? 1 : projetos.length < 4 ? projetos.length : 4,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        autoplay: true,
    };

    if (loading)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Carregando...</div></Typography>
        </Box>
    )

    if (!projetos.length)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Nenhum projeto encontrado</div></Typography>
        </Box>
    )

    return (
        <Slider {...settings} >
            {projetos.map((projeto, i) => (
                <CarrousselItem projeto={projeto} key={i} />
            ))}
        </Slider>
    )
}

export default SlickCarroussel