import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { getBytes, ref, uploadBytes } from "firebase/storage";
import { Configuracao, ConfiguracaoUpdate } from "types/configuracao";
import { database, storage } from "utils/firebase";

const dbcollection = collection(database, 'configuracoes');

function _arrayBufferToBase64( buffer:any ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


async function _getImagem (imagem: string){
    const imageRef = ref(storage, imagem);
    const imageBytes = await getBytes(imageRef);
    const base64String = _arrayBufferToBase64(imageBytes);
    return `data:image/png;base64,${base64String}`;
}

const ConfiguracoesService = {
    get: async () => {
        try {
            let configuracao: Configuracao;
            const docRef = doc(dbcollection, "configuracao");
            const docSnap = await getDoc(docRef);
            configuracao = {...docSnap.data(), id: docSnap.id} as Configuracao;
            return configuracao;
        }catch (e:any) {
            await Promise.reject(e)
            //toast.error(JSON.stringify(e));
            throw new Error(JSON.stringify(e));
        }
    },
    getWithImage: async () => {
        try {
            let configuracao: Configuracao;
            const docRef = doc(dbcollection, "configuracao");
            const docSnap = await getDoc(docRef);
            if(docSnap.data()?.foto){
                let imagem = await _getImagem(docSnap.data()?.foto);
                configuracao = {...docSnap.data(), id: docSnap.id, foto: imagem} as Configuracao;
            }else{
                configuracao = {...docSnap.data(), id: docSnap.id} as Configuracao;
            }
            return configuracao;
        }catch (e:any) {
            await Promise.reject(e)
            //toast.error(JSON.stringify(e));
            throw new Error(JSON.stringify(e));
        }
    },
    update: async (configuracao: ConfiguracaoUpdate) => {
        try {
            let configuracaoAtual: Configuracao = {...configuracao, foto: ''};
            delete configuracaoAtual.id;
            let img = configuracao.foto.length > 0 ? configuracao.foto[0] : null;
            if(img){
                const mountainsRef = ref(storage, 'configuracoes/foto-perfil.jpg');
                await uploadBytes(mountainsRef, img);
                configuracaoAtual.foto = 'configuracoes/foto-perfil.jpg';
            }
            const docRef = doc(dbcollection, "configuracao");
            await setDoc(docRef, configuracaoAtual);
            return configuracaoAtual;
        }catch (e:any) {
            await Promise.reject(e)
           // toast.error(JSON.stringify(e));
            throw new Error(JSON.stringify(e));
        }
    }
};



export default ConfiguracoesService;