import {Grid, styled} from "@mui/material";
import Typography from "@mui/material/Typography";
import ProcessoItem from "components/processoItem";
import Container from "@mui/material/Container";

const Title = styled('span')({});

const ProcessoSection = () => {
    return (
        <Container component={"section"} sx={{minHeight: "450px", display: 'flex', justifyContent: "center", alignItems: "start"}}>
            <Grid container maxWidth={"md"}>
                <Grid item xs={12} sx={{textAlign: "center", mb: 5}}>
                    <Typography sx={{fontSize: 32, fontWeight: 400}}>Processos de desenvolvimento do <Title sx={{
                        fontWeight: 800,
                        background: (theme) => `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                        backgroundClip: 'text',
                        color: 'transparent',
                    }}>projeto</Title></Typography>
                </Grid>
                <Grid item container xs={12}
                      sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "start",
                          minHeight: 300,
                          borderWidth: 2,
                          borderStyle: 'solid',
                          borderColor: 'transparent',
                          borderRadius: 6,
                          p: 3,
                          background: (theme) => `linear-gradient(black, black) padding-box, linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%) border-box;`,
                      }}>
                    <Grid item xs={12} md={3}>
                        <ProcessoItem order={1} processo={"briefing"}
                                      data={"O briefing é o ponto de partida para um projeto bem elaborado. É a etapa em que cliente e designer colaboram na coleta e troca de informações, estabelecendo as bases para o projeto."}
                                      last={false}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ProcessoItem order={2} processo={"brainstorm"}
                                      data={"O brainstorm é o pulsar criativo do projeto, onde ideias fluem livremente. Essa fase permite que o projeto alcance seu potencial máximo e promove a originalidade no seu desenvolvimento."}
                                      last={false}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ProcessoItem order={3} processo={"realização"}
                                      data={"Essa é a etapa em que as ideias ganham vida, o design toma forma e a visão do projeto se materializa. Neste estágio, a execução é técnica e criativa, transformando conceitos em realidade."}
                                      last={false}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ProcessoItem order={4} processo={"projeto final"}
                                      data={"Cada passo, desde as ideias iniciais até a criação, resulta em algo visual que não apenas atende, mas supera expectativas. Não entregamos apenas uma aparência, mas uma experiência que deixa uma marca duradoura."}
                                      last={true}/>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}


export default ProcessoSection;