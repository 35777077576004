import {Box, styled} from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type Props = {
    order: number;
    processo: string;
    data: string;
    last: boolean;
}


const Ordem = styled(Typography)(({theme}) => ({
    fontSize: "32px",
    padding: 0,
    fontWeight: 900,
    background: `linear-gradient(to left, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
    backgroundClip: 'text',
    color: 'transparent',
}));

const Processo = styled(Typography)(({theme}) => ({
    fontSize: 22,
    padding: 0,
    lineHeight: "32px",
    fontWeight: 600,
    color: "white",
    marginLeft: 8,
}));

const Conteudo = styled(Typography)(({theme}) => ({
    fontSize: 16,
    padding: 0,
    fontWeight: 400,
    color: "white",
    marginTop: 10,
}));

const ProcessoItem = ({order, processo, last, data}: Props) => {
    return (
        <Box sx={{width: "100%", height: "100%", p: .5}}>
            <Box sx={{display: "flex", alignItems: "baseline"}}>
                <Ordem>{order}.</Ordem>
                <Box sx={{display: "flex" , alignItems: "center"}}>
                    <Processo>{processo}  </Processo>{!last && <ArrowForwardIosIcon sx={{ml:0, p:0}} />}
                </Box>
            </Box>
            <Conteudo>{data}</Conteudo>
        </Box>
    )
}

export default ProcessoItem;