import Slider, {Settings} from "react-slick";
import DepoimentoItem from "components/carrousselDepoimento/depoimento";
import { useCallback, useEffect, useState } from "react";
import { Depoimento } from "types/depoimento";
import DepoimentoService from "services/depoimento";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";


const CarrousselDepoimento = () => {
    const [depoimentos , setDepoimentos] = useState<Depoimento[]>([])
    const [loading, setLoading] = useState(false)
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const getDepoimentos = useCallback(() => {
        setLoading(true)
        DepoimentoService.getAll().then((response) => {
            setDepoimentos(response)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        getDepoimentos()
    }, [getDepoimentos])

    const settings: Settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: downSm ? 1 : depoimentos.length < 6 ? depoimentos.length : 6,
        slidesToScroll: 1,
        centerMode: true,
        arrows: false,
        autoplay: true,
        rtl: true,
    };

    if (loading)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Carregando...</div></Typography>
        </Box>
    )

    if (!depoimentos.length)
    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography sx={{fontSize: 32, fontWeight: 400}}><div>Nenhum depoimento encontrado</div></Typography>
        </Box>
    )


    return (
        <Slider {...settings} >
            {depoimentos.map((depoimento, i) => (
                <DepoimentoItem depoimento={depoimento} key={i} />
            ))}
        </Slider>
    )
}

export default CarrousselDepoimento